/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const calculateScores = /* GraphQL */ `
  query CalculateScores($year: Int) {
    calculateScores(year: $year) {
      year {
        id
        year
        name
        __typename
      }
      scoresMappedByTeam {
        team {
          id
          yearID
          name
          players {
            items {
              id
              teamID
              yearID
              firstName
              lastName
              position
              sub
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          scorecards {
            items {
              id
              teamID
              courseID
              pairing
              scores {
                items {
                  id
                  scorecardID
                  holeNumber
                  score
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              team {
                id
                yearID
                name
                players {
                  items {
                    id
                    teamID
                    yearID
                    firstName
                    lastName
                    position
                    sub
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                scorecards {
                  items {
                    id
                    teamID
                    courseID
                    pairing
                    scores {
                      nextToken
                      __typename
                    }
                    team {
                      id
                      yearID
                      name
                      createdAt
                      updatedAt
                      __typename
                    }
                    course {
                      id
                      name
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              course {
                id
                name
                holes {
                  items {
                    id
                    courseID
                    number
                    par
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        score
        __typename
      }
      __typename
    }
  }
`;
export const getYear = /* GraphQL */ `
  query GetYear($id: ID!) {
    getYear(id: $id) {
      id
      year
      name
      day1Course {
        id
        name
        holes {
          items {
            id
            courseID
            number
            par
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      day2Course {
        id
        name
        holes {
          items {
            id
            courseID
            number
            par
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      teams {
        items {
          id
          yearID
          name
          players {
            items {
              id
              teamID
              yearID
              firstName
              lastName
              position
              sub
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          scorecards {
            items {
              id
              teamID
              courseID
              pairing
              scores {
                items {
                  id
                  scorecardID
                  holeNumber
                  score
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              team {
                id
                yearID
                name
                players {
                  items {
                    id
                    teamID
                    yearID
                    firstName
                    lastName
                    position
                    sub
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                scorecards {
                  items {
                    id
                    teamID
                    courseID
                    pairing
                    scores {
                      nextToken
                      __typename
                    }
                    team {
                      id
                      yearID
                      name
                      createdAt
                      updatedAt
                      __typename
                    }
                    course {
                      id
                      name
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              course {
                id
                name
                holes {
                  items {
                    id
                    courseID
                    number
                    par
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      yearDay1CourseId
      yearDay2CourseId
      __typename
    }
  }
`;
export const listYears = /* GraphQL */ `
  query ListYears(
    $filter: ModelYearFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listYears(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        year
        name
        day1Course {
          id
          name
          holes {
            items {
              id
              courseID
              number
              par
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        day2Course {
          id
          name
          holes {
            items {
              id
              courseID
              number
              par
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        teams {
          items {
            id
            yearID
            name
            players {
              items {
                id
                teamID
                yearID
                firstName
                lastName
                position
                sub
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            scorecards {
              items {
                id
                teamID
                courseID
                pairing
                scores {
                  items {
                    id
                    scorecardID
                    holeNumber
                    score
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                team {
                  id
                  yearID
                  name
                  players {
                    items {
                      id
                      teamID
                      yearID
                      firstName
                      lastName
                      position
                      sub
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  scorecards {
                    items {
                      id
                      teamID
                      courseID
                      pairing
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                course {
                  id
                  name
                  holes {
                    items {
                      id
                      courseID
                      number
                      par
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        yearDay1CourseId
        yearDay2CourseId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const yearByYear = /* GraphQL */ `
  query YearByYear(
    $year: Int!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelYearFilterInput
    $limit: Int
    $nextToken: String
  ) {
    yearByYear(
      year: $year
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        year
        name
        day1Course {
          id
          name
          holes {
            items {
              id
              courseID
              number
              par
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        day2Course {
          id
          name
          holes {
            items {
              id
              courseID
              number
              par
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        teams {
          items {
            id
            yearID
            name
            players {
              items {
                id
                teamID
                yearID
                firstName
                lastName
                position
                sub
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            scorecards {
              items {
                id
                teamID
                courseID
                pairing
                scores {
                  items {
                    id
                    scorecardID
                    holeNumber
                    score
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                team {
                  id
                  yearID
                  name
                  players {
                    items {
                      id
                      teamID
                      yearID
                      firstName
                      lastName
                      position
                      sub
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  scorecards {
                    items {
                      id
                      teamID
                      courseID
                      pairing
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                course {
                  id
                  name
                  holes {
                    items {
                      id
                      courseID
                      number
                      par
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        yearDay1CourseId
        yearDay2CourseId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      name
      holes {
        items {
          id
          courseID
          number
          par
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCourses = /* GraphQL */ `
  query ListCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        holes {
          items {
            id
            courseID
            number
            par
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHole = /* GraphQL */ `
  query GetHole($id: ID!) {
    getHole(id: $id) {
      id
      courseID
      number
      par
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listHoles = /* GraphQL */ `
  query ListHoles(
    $filter: ModelHoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        courseID
        number
        par
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      yearID
      name
      players {
        items {
          id
          teamID
          yearID
          firstName
          lastName
          position
          sub
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      scorecards {
        items {
          id
          teamID
          courseID
          pairing
          scores {
            items {
              id
              scorecardID
              holeNumber
              score
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          team {
            id
            yearID
            name
            players {
              items {
                id
                teamID
                yearID
                firstName
                lastName
                position
                sub
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            scorecards {
              items {
                id
                teamID
                courseID
                pairing
                scores {
                  items {
                    id
                    scorecardID
                    holeNumber
                    score
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                team {
                  id
                  yearID
                  name
                  players {
                    items {
                      id
                      teamID
                      yearID
                      firstName
                      lastName
                      position
                      sub
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  scorecards {
                    items {
                      id
                      teamID
                      courseID
                      pairing
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                course {
                  id
                  name
                  holes {
                    items {
                      id
                      courseID
                      number
                      par
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          course {
            id
            name
            holes {
              items {
                id
                courseID
                number
                par
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        yearID
        name
        players {
          items {
            id
            teamID
            yearID
            firstName
            lastName
            position
            sub
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        scorecards {
          items {
            id
            teamID
            courseID
            pairing
            scores {
              items {
                id
                scorecardID
                holeNumber
                score
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            team {
              id
              yearID
              name
              players {
                items {
                  id
                  teamID
                  yearID
                  firstName
                  lastName
                  position
                  sub
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              scorecards {
                items {
                  id
                  teamID
                  courseID
                  pairing
                  scores {
                    items {
                      id
                      scorecardID
                      holeNumber
                      score
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  team {
                    id
                    yearID
                    name
                    players {
                      nextToken
                      __typename
                    }
                    scorecards {
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  course {
                    id
                    name
                    holes {
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            course {
              id
              name
              holes {
                items {
                  id
                  courseID
                  number
                  par
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPlayer = /* GraphQL */ `
  query GetPlayer($id: ID!) {
    getPlayer(id: $id) {
      id
      teamID
      yearID
      firstName
      lastName
      position
      sub
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPlayers = /* GraphQL */ `
  query ListPlayers(
    $filter: ModelPlayerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlayers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        teamID
        yearID
        firstName
        lastName
        position
        sub
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const playerBySubByYearID = /* GraphQL */ `
  query PlayerBySubByYearID(
    $sub: String!
    $yearID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPlayerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    playerBySubByYearID(
      sub: $sub
      yearID: $yearID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        teamID
        yearID
        firstName
        lastName
        position
        sub
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getScorecard = /* GraphQL */ `
  query GetScorecard($id: ID!) {
    getScorecard(id: $id) {
      id
      teamID
      courseID
      pairing
      scores {
        items {
          id
          scorecardID
          holeNumber
          score
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      team {
        id
        yearID
        name
        players {
          items {
            id
            teamID
            yearID
            firstName
            lastName
            position
            sub
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        scorecards {
          items {
            id
            teamID
            courseID
            pairing
            scores {
              items {
                id
                scorecardID
                holeNumber
                score
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            team {
              id
              yearID
              name
              players {
                items {
                  id
                  teamID
                  yearID
                  firstName
                  lastName
                  position
                  sub
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              scorecards {
                items {
                  id
                  teamID
                  courseID
                  pairing
                  scores {
                    items {
                      id
                      scorecardID
                      holeNumber
                      score
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  team {
                    id
                    yearID
                    name
                    players {
                      nextToken
                      __typename
                    }
                    scorecards {
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  course {
                    id
                    name
                    holes {
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            course {
              id
              name
              holes {
                items {
                  id
                  courseID
                  number
                  par
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      course {
        id
        name
        holes {
          items {
            id
            courseID
            number
            par
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listScorecards = /* GraphQL */ `
  query ListScorecards(
    $filter: ModelScorecardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScorecards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        teamID
        courseID
        pairing
        scores {
          items {
            id
            scorecardID
            holeNumber
            score
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        team {
          id
          yearID
          name
          players {
            items {
              id
              teamID
              yearID
              firstName
              lastName
              position
              sub
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          scorecards {
            items {
              id
              teamID
              courseID
              pairing
              scores {
                items {
                  id
                  scorecardID
                  holeNumber
                  score
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              team {
                id
                yearID
                name
                players {
                  items {
                    id
                    teamID
                    yearID
                    firstName
                    lastName
                    position
                    sub
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                scorecards {
                  items {
                    id
                    teamID
                    courseID
                    pairing
                    scores {
                      nextToken
                      __typename
                    }
                    team {
                      id
                      yearID
                      name
                      createdAt
                      updatedAt
                      __typename
                    }
                    course {
                      id
                      name
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              course {
                id
                name
                holes {
                  items {
                    id
                    courseID
                    number
                    par
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        course {
          id
          name
          holes {
            items {
              id
              courseID
              number
              par
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const scorecardByTeamByPairing = /* GraphQL */ `
  query ScorecardByTeamByPairing(
    $teamID: ID!
    $pairing: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelScorecardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    scorecardByTeamByPairing(
      teamID: $teamID
      pairing: $pairing
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        teamID
        courseID
        pairing
        scores {
          items {
            id
            scorecardID
            holeNumber
            score
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        team {
          id
          yearID
          name
          players {
            items {
              id
              teamID
              yearID
              firstName
              lastName
              position
              sub
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          scorecards {
            items {
              id
              teamID
              courseID
              pairing
              scores {
                items {
                  id
                  scorecardID
                  holeNumber
                  score
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              team {
                id
                yearID
                name
                players {
                  items {
                    id
                    teamID
                    yearID
                    firstName
                    lastName
                    position
                    sub
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                scorecards {
                  items {
                    id
                    teamID
                    courseID
                    pairing
                    scores {
                      nextToken
                      __typename
                    }
                    team {
                      id
                      yearID
                      name
                      createdAt
                      updatedAt
                      __typename
                    }
                    course {
                      id
                      name
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              course {
                id
                name
                holes {
                  items {
                    id
                    courseID
                    number
                    par
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        course {
          id
          name
          holes {
            items {
              id
              courseID
              number
              par
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getScore = /* GraphQL */ `
  query GetScore($id: ID!) {
    getScore(id: $id) {
      id
      scorecardID
      holeNumber
      score
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listScores = /* GraphQL */ `
  query ListScores(
    $filter: ModelScoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        scorecardID
        holeNumber
        score
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTrashTalk = /* GraphQL */ `
  query GetTrashTalk($id: ID!) {
    getTrashTalk(id: $id) {
      id
      yearID
      trashTalk
      player {
        id
        teamID
        yearID
        firstName
        lastName
        position
        sub
        createdAt
        updatedAt
        __typename
      }
      owner
      createdAt
      updatedAt
      trashTalkPlayerId
      __typename
    }
  }
`;
export const listTrashTalks = /* GraphQL */ `
  query ListTrashTalks(
    $filter: ModelTrashTalkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrashTalks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        yearID
        trashTalk
        player {
          id
          teamID
          yearID
          firstName
          lastName
          position
          sub
          createdAt
          updatedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        trashTalkPlayerId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
