/* src/App.js */
import React, { useEffect, useState } from 'react'
import { withAuthenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import { fetchAuthSession, signOut } from 'aws-amplify/auth'
import Years from './components/Years'
import Courses from './components/Courses'
import Container from '@mui/material/Container'
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom'
import Drawer from '@mui/material/Drawer'
import ListItem from '@mui/material/ListItem'
import AppBar from '@mui/material/AppBar'
import CssBaseline from '@mui/material/CssBaseline'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import EditCourse from './components/EditCourse'
import EditYear from './components/EditYear'
import EditTeam from './components/EditTeam'
import LeaderboardContainer from './components/LeaderboardContainer'
import ScorecardPage from './components/ScorecardPage'
import TrashTalk from './components/TrashTalk'
import Button from '@mui/material/Button'
import { SnackbarProvider } from 'notistack'
import Scores from './components/admin/Scores'
import AdminCourses from './components/admin/Courses'
import Privacy from './components/Privacy'

const App = () => {
  const [drawerOpen, setMobileOpen] = useState(false)
  const [groups, setGroups] = useState([])

  useEffect(() => {
    const getAuth = async () => {
      const session = await fetchAuthSession()
      const groups = session.tokens.idToken.payload['cognito:groups']
      if (groups) {
        setGroups(groups)
      }
    }
    getAuth()
  }, [])

  const onLogoutClicked = () => {
    signOut()
  }

  const drawer = (
    <>
      {groups.includes('admin') && (
        <ListItem>
          <Link to="/years">Years</Link>
        </ListItem>
      )}
      {groups.includes('admin') && (
        <ListItem>
          <Link to="/courses">Courses</Link>
        </ListItem>
      )}
      {groups.includes('admin') && (
        <ListItem>
          <Link to="/admin/courses">Courses Admin</Link>
        </ListItem>
      )}
      {groups.includes('admin') && (
        <ListItem>
          <Link to="/admin/scores">Scores Admin</Link>
        </ListItem>
      )}
      <ListItem>
        <Link to="/leaderboard/2024">Leaderboard</Link>
      </ListItem>
      <ListItem>
        <Button type="button" color="primary" onClick={onLogoutClicked}>
          Logout
        </Button>
      </ListItem>
    </>
  )

  const handleDrawerToggle = () => {
    setMobileOpen(!drawerOpen)
  }

  return (
    <SnackbarProvider>
      <Router>
        <CssBaseline />
        <Box sx={{ display: 'flex' }}>
          <AppBar component="nav">
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap>
                The Ballwashers Invitational
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            anchor="left"
            open={drawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            component="nav"
          >
            {drawer}
          </Drawer>
          <Container component="main">
            <Toolbar />
            <Routes>
              <Route
                path="/scorecard/:teamID/:pairing"
                element={<ScorecardPage />}
              />
              <Route
                path="/leaderboard/:year"
                element={<LeaderboardContainer />}
              />
              <Route path="/leaderboard" element={<LeaderboardContainer />} />
              <Route path="/years/:yearID/trashtalk" element={<TrashTalk />} />

              <Route
                path="/years/:yearID/teams/:teamID"
                element={<EditTeam />}
              />

              <Route path="/years/:yearID" element={<EditYear />} />

              <Route path="/years" element={<Years />} />

              <Route path="/courses/:courseID" element={<EditCourse />} />

              <Route path="/courses" element={<Courses />} />

              <Route path="/admin/scores" element={<Scores />} />

              <Route path="/admin/courses" element={<AdminCourses />} />
              <Route path="/privacy" element={<Privacy />} />

              <Route element={<LeaderboardContainer />} />
            </Routes>
          </Container>
        </Box>
      </Router>
    </SnackbarProvider>
  )
}

export default withAuthenticator(App)
