import React from 'react'
import { useParams } from 'react-router-dom'

import Scorecard from './Scorecard'

const ScorecardPage = () => {
  const { teamID, pairing } = useParams()

  return <Scorecard teamID={teamID} pairing={pairing}></Scorecard>
}

export default ScorecardPage
