import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Button,
} from '@mui/material'
import { generateClient } from 'aws-amplify/api'
import React, { useEffect, useState } from 'react'
import { deleteHole } from '../../graphql/mutations'
import { listCourses } from '../../graphql/queries'

const client = generateClient()

const Courses = () => {
  const [courses, setCourses] = useState([])

  const fetchCourses = async () => {
    const coursesData = await client.graphql({
      query: listCourses,
    })
    const courses = coursesData.data.listCourses.items
    setCourses(courses)
  }

  useEffect(() => {
    fetchCourses()
  }, [])

  const onClickDelete = async (holeId) => {
    const deletedHole = await client.graphql({
      query: deleteHole,
      variables: { input: { id: holeId } },
    })
    console.log(deletedHole)
  }

  return (
    <>
      <h1>Courses</h1>
      {courses.map((course) => {
        return (
          <>
            <h2 key={course.id}>{course.name}</h2>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Hole</TableCell>
                  <TableCell>Par</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {course.holes.items.map((hole) => {
                  return (
                    <TableRow key={hole.id}>
                      <TableCell>{hole.number}</TableCell>
                      <TableCell>{hole.par}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => onClickDelete(hole.id)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </>
        )
      })}
    </>
  )
}

export default Courses
