import React from 'react'
import Grid from '@mui/material/Grid'

const Privacy = () => {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item>
        <div>
          The Ballwashers Invitational scoring app does not collect data and
          maintains your privacy.
        </div>
      </Grid>
    </Grid>
  )
}

export default Privacy
