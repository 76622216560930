import React, { useState, useEffect, Fragment, useCallback } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import * as queries from '../graphql/queries'
import * as mutations from '../graphql/mutations'
import { generateClient } from 'aws-amplify/api'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import { useSnackbar } from 'notistack'
import Button from '@mui/material/Button'
import { green, blue, red } from '@mui/material/colors'

const client = generateClient()

const ScoreSelect = (props) => {
  const [score, setScore] = useState('')

  useEffect(() => {
    if (props.score) {
      setScore(props.score)
    }
  }, [props.score])

  const handleScoreSelected = (event) => {
    setScore(event.target.value)
    props.updateScore(props.holeNumber, event.target.value, props.id)
  }

  const createMenuItems = () => {
    const menuItems = []
    for (let i = 1; i < 16; i++) {
      menuItems.push(
        <MenuItem key={i} value={i}>
          {i}
        </MenuItem>,
      )
    }

    return menuItems
  }

  return (
    <FormControl fullWidth>
      <InputLabel id={`score-label-${props.holeNumber}`}>Score</InputLabel>
      <Select
        labelId={`score-label-${props.holeNumber}`}
        id={`score-select-${props.holeNumber}`}
        value={score}
        onChange={handleScoreSelected}
        disabled={props.saving}
        label="Score"
      >
        {createMenuItems()}
      </Select>
    </FormControl>
  )
}

ScoreSelect.propTypes = {
  holeNumber: PropTypes.number.isRequired,
  score: PropTypes.number,
  updateScore: PropTypes.func,
  id: PropTypes.string,
  scorecardID: PropTypes.string.isRequired,
  saving: PropTypes.bool,
}

const Scorecard = (props) => {
  const [scorecard, setScorecard] = useState()
  const [scores, setScores] = useState()
  const [holes, setHoles] = useState()
  const [saving, setSaving] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const fetchScorecard = useCallback(async () => {
    const scorecardData = await client.graphql({
      query: queries.scorecardByTeamByPairing,
      variables: {
        teamID: props.teamID,
        pairing: { eq: props.pairing },
      },
    })
    const scorecard = scorecardData.data.scorecardByTeamByPairing.items[0]
    setScorecard(scorecard)
    setHoles(scorecard.course.holes.items)
    setScores([...scorecard.scores.items])
  })

  useEffect(() => {
    // const fetchScorecard = async () => {
    //   const scorecardData = await client.graphql(
    //     graphqlOperation(queries.scorecardByTeamByPairing, {
    //       teamID: props.teamID,
    //       pairing: { eq: props.pairing },
    //     })
    //   )
    //   const scorecard = scorecardData.data.scorecardByTeamByPairing.items[0]
    //   setScorecard(scorecard)
    //   setHoles(scorecard.course.holes.items)
    //   setScores([...scorecard.scores.items])
    // }
    fetchScorecard()
  }, [props.teamID, props.pairing])

  const updateScore = async (holeNumber, score, id) => {
    const scoreToUpdate = {
      id: id,
      scorecardID: scorecard.id,
      holeNumber: holeNumber,
      score: score,
    }

    try {
      setSaving(true)
      const updatedScoreData = await client.graphql({
        query: mutations.updateScore,
        variables: { input: scoreToUpdate },
      })

      const updatedScore = updatedScoreData.data.updateScore
      enqueueSnackbar('Score Updated!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        autoHideDuration: 2000,
      })
      setScores(
        scores.map((current) =>
          current.id === updatedScore.id ? updatedScore : current,
        ),
      )
    } catch (err) {
      setSaving(false)
      enqueueSnackbar('Score Update failed :(.', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        autoHideDuration: 2000,
      })
      console.log(err)
    } finally {
      setSaving(false)
    }
  }

  const createTableCells = () => {
    const holeComponents = []
    for (let i = 1; i < 19; i++) {
      let score = scores.find((element) => element.holeNumber === i)

      if (!score) {
        score = {
          holeNumber: i,
          scorecardID: scorecard.id,
        }
      }

      holeComponents.push(
        <Fragment key={i}>
          <TableRow>
            <TableCell scope="row">{i}</TableCell>
            <TableCell scope="row">{holes[i - 1].par}</TableCell>
            <TableCell scope="row">
              <Grid container direction="column">
                <Grid item>
                  <ScoreSelect
                    {...score}
                    updateScore={updateScore}
                    saving={saving}
                  ></ScoreSelect>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={3}>
              <Grid container item justifyContent="space-between">
                <Button
                  onClick={() =>
                    updateScore(
                      holes[i - 1].number,
                      holes[i - 1].par - 1,
                      score.id,
                    )
                  }
                  variant="contained"
                  sx={{ backgroundColor: green[500] }}
                  size="small"
                  disabled={saving}
                >
                  Birdie
                </Button>
                <Button
                  onClick={() =>
                    updateScore(holes[i - 1].number, holes[i - 1].par, score.id)
                  }
                  sx={{ backgroundColor: blue[500] }}
                  variant="contained"
                  size="small"
                  disabled={saving}
                >
                  Par
                </Button>
                <Button
                  onClick={() =>
                    updateScore(
                      holes[i - 1].number,
                      holes[i - 1].par + 1,
                      score.id,
                    )
                  }
                  variant="contained"
                  sx={{ backgroundColor: red[500] }}
                  size="small"
                  disabled={saving}
                >
                  Bogie
                </Button>
              </Grid>
            </TableCell>
          </TableRow>
        </Fragment>,
      )
    }
    return holeComponents
  }

  const totalScore = () => {
    const score = scores.reduce((total, current) => {
      const hole = holes.find(
        (element) => element.number === current.holeNumber,
      )
      if (
        current.score === undefined ||
        current.score === null ||
        current.score < 1
      ) {
        return total
      }
      return total + (current.score - hole.par)
    }, 0)
    if (score === 0) {
      return 'E'
    } else if (score > 0) {
      return `+${score}`
    } else {
      return score
    }
  }

  if (scorecard && scores && holes) {
    return (
      <Grid container direction="column" spacing={1}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography variant="h5">{`${scorecard.team.name}: ${props.pairing}`}</Typography>
          </Grid>
          <Grid container item spacing={1}>
            {scorecard.team.players.items
              .sort((playerA, playerB) =>
                playerA.position.localeCompare(playerB.position),
              )
              .filter((player) => props.pairing.includes(player.position))
              .map((player) => (
                <Grid key={player.position} item>
                  <Typography variant="caption">{`${player.lastName}`}</Typography>
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item>
            <Typography variant="h6">{`${scorecard.course.name}`}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6">{totalScore()}</Typography>
          </Grid>
        </Grid>
        <Grid container item>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Hole</TableCell>
                  <TableCell>Par</TableCell>
                  <TableCell>Score</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{createTableCells()}</TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  )
}

Scorecard.propTypes = {
  teamID: PropTypes.string.isRequired,
  pairing: PropTypes.string.isRequired,
}

export default Scorecard
