/* src/App.js */
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { generateClient } from 'aws-amplify/api'
import React, { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import { createYear } from '../graphql/mutations'
import { listYears } from '../graphql/queries'

const client = generateClient()

const initialState = { name: '', year: '' }

const Years = () => {
  const [formState, setFormState] = useState(initialState)
  const [years, setYears] = useState([])

  useEffect(() => {
    fetchYears()
  }, [])

  const setInput = (key, value) => {
    setFormState({ ...formState, [key]: value })
  }

  const fetchYears = async () => {
    const yearsData = await client.graphql({ query: listYears })
    const years = yearsData.data.listYears.items
    setYears(years)
  }

  const addYear = async (event) => {
    event.preventDefault()
    try {
      if (!formState.name || !formState.year) return
      const year = { ...formState }
      setYears([...years, year])
      setFormState(initialState)
      await client.graphql({ query: createYear, variables: { input: year } })
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="h1">Years</Typography>
      </Grid>
      <Grid container>
        <Grid item>
          <form onSubmit={(event) => addYear(event)}>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Typography variant="h3">Create</Typography>
              </Grid>
              <Grid item>
                <TextField
                  onChange={(event) => setInput('name', event.target.value)}
                  label="Name"
                />
              </Grid>
              <Grid item>
                <TextField
                  onChange={(event) => setInput('year', event.target.value)}
                  label="Year"
                />
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained" color="primary">
                  Add
                </Button>
              </Grid>
              <Grid item>
                <Typography variant="h3">List</Typography>
              </Grid>
              <Grid container item direction="column" rowSpacing={2}>
                {years.map((year) => (
                  <Grid
                    container
                    item
                    columnSpacing={2}
                    key={year.year}
                    alignItems="center"
                  >
                    <Grid item>
                      <Link component={RouterLink} to={`/years/${year.id}`}>
                        {year.name}
                      </Link>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">({year.year})</Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Years
