/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createYear = /* GraphQL */ `
  mutation CreateYear(
    $input: CreateYearInput!
    $condition: ModelYearConditionInput
  ) {
    createYear(input: $input, condition: $condition) {
      id
      year
      name
      day1Course {
        id
        name
        holes {
          items {
            id
            courseID
            number
            par
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      day2Course {
        id
        name
        holes {
          items {
            id
            courseID
            number
            par
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      teams {
        items {
          id
          yearID
          name
          players {
            items {
              id
              teamID
              yearID
              firstName
              lastName
              position
              sub
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          scorecards {
            items {
              id
              teamID
              courseID
              pairing
              scores {
                items {
                  id
                  scorecardID
                  holeNumber
                  score
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              team {
                id
                yearID
                name
                players {
                  items {
                    id
                    teamID
                    yearID
                    firstName
                    lastName
                    position
                    sub
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                scorecards {
                  items {
                    id
                    teamID
                    courseID
                    pairing
                    scores {
                      nextToken
                      __typename
                    }
                    team {
                      id
                      yearID
                      name
                      createdAt
                      updatedAt
                      __typename
                    }
                    course {
                      id
                      name
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              course {
                id
                name
                holes {
                  items {
                    id
                    courseID
                    number
                    par
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      yearDay1CourseId
      yearDay2CourseId
      __typename
    }
  }
`;
export const updateYear = /* GraphQL */ `
  mutation UpdateYear(
    $input: UpdateYearInput!
    $condition: ModelYearConditionInput
  ) {
    updateYear(input: $input, condition: $condition) {
      id
      year
      name
      day1Course {
        id
        name
        holes {
          items {
            id
            courseID
            number
            par
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      day2Course {
        id
        name
        holes {
          items {
            id
            courseID
            number
            par
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      teams {
        items {
          id
          yearID
          name
          players {
            items {
              id
              teamID
              yearID
              firstName
              lastName
              position
              sub
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          scorecards {
            items {
              id
              teamID
              courseID
              pairing
              scores {
                items {
                  id
                  scorecardID
                  holeNumber
                  score
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              team {
                id
                yearID
                name
                players {
                  items {
                    id
                    teamID
                    yearID
                    firstName
                    lastName
                    position
                    sub
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                scorecards {
                  items {
                    id
                    teamID
                    courseID
                    pairing
                    scores {
                      nextToken
                      __typename
                    }
                    team {
                      id
                      yearID
                      name
                      createdAt
                      updatedAt
                      __typename
                    }
                    course {
                      id
                      name
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              course {
                id
                name
                holes {
                  items {
                    id
                    courseID
                    number
                    par
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      yearDay1CourseId
      yearDay2CourseId
      __typename
    }
  }
`;
export const deleteYear = /* GraphQL */ `
  mutation DeleteYear(
    $input: DeleteYearInput!
    $condition: ModelYearConditionInput
  ) {
    deleteYear(input: $input, condition: $condition) {
      id
      year
      name
      day1Course {
        id
        name
        holes {
          items {
            id
            courseID
            number
            par
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      day2Course {
        id
        name
        holes {
          items {
            id
            courseID
            number
            par
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      teams {
        items {
          id
          yearID
          name
          players {
            items {
              id
              teamID
              yearID
              firstName
              lastName
              position
              sub
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          scorecards {
            items {
              id
              teamID
              courseID
              pairing
              scores {
                items {
                  id
                  scorecardID
                  holeNumber
                  score
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              team {
                id
                yearID
                name
                players {
                  items {
                    id
                    teamID
                    yearID
                    firstName
                    lastName
                    position
                    sub
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                scorecards {
                  items {
                    id
                    teamID
                    courseID
                    pairing
                    scores {
                      nextToken
                      __typename
                    }
                    team {
                      id
                      yearID
                      name
                      createdAt
                      updatedAt
                      __typename
                    }
                    course {
                      id
                      name
                      createdAt
                      updatedAt
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              course {
                id
                name
                holes {
                  items {
                    id
                    courseID
                    number
                    par
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      yearDay1CourseId
      yearDay2CourseId
      __typename
    }
  }
`;
export const createCourse = /* GraphQL */ `
  mutation CreateCourse(
    $input: CreateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    createCourse(input: $input, condition: $condition) {
      id
      name
      holes {
        items {
          id
          courseID
          number
          par
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCourse = /* GraphQL */ `
  mutation UpdateCourse(
    $input: UpdateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    updateCourse(input: $input, condition: $condition) {
      id
      name
      holes {
        items {
          id
          courseID
          number
          par
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCourse = /* GraphQL */ `
  mutation DeleteCourse(
    $input: DeleteCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    deleteCourse(input: $input, condition: $condition) {
      id
      name
      holes {
        items {
          id
          courseID
          number
          par
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createHole = /* GraphQL */ `
  mutation CreateHole(
    $input: CreateHoleInput!
    $condition: ModelHoleConditionInput
  ) {
    createHole(input: $input, condition: $condition) {
      id
      courseID
      number
      par
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateHole = /* GraphQL */ `
  mutation UpdateHole(
    $input: UpdateHoleInput!
    $condition: ModelHoleConditionInput
  ) {
    updateHole(input: $input, condition: $condition) {
      id
      courseID
      number
      par
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteHole = /* GraphQL */ `
  mutation DeleteHole(
    $input: DeleteHoleInput!
    $condition: ModelHoleConditionInput
  ) {
    deleteHole(input: $input, condition: $condition) {
      id
      courseID
      number
      par
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      yearID
      name
      players {
        items {
          id
          teamID
          yearID
          firstName
          lastName
          position
          sub
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      scorecards {
        items {
          id
          teamID
          courseID
          pairing
          scores {
            items {
              id
              scorecardID
              holeNumber
              score
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          team {
            id
            yearID
            name
            players {
              items {
                id
                teamID
                yearID
                firstName
                lastName
                position
                sub
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            scorecards {
              items {
                id
                teamID
                courseID
                pairing
                scores {
                  items {
                    id
                    scorecardID
                    holeNumber
                    score
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                team {
                  id
                  yearID
                  name
                  players {
                    items {
                      id
                      teamID
                      yearID
                      firstName
                      lastName
                      position
                      sub
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  scorecards {
                    items {
                      id
                      teamID
                      courseID
                      pairing
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                course {
                  id
                  name
                  holes {
                    items {
                      id
                      courseID
                      number
                      par
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          course {
            id
            name
            holes {
              items {
                id
                courseID
                number
                par
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      yearID
      name
      players {
        items {
          id
          teamID
          yearID
          firstName
          lastName
          position
          sub
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      scorecards {
        items {
          id
          teamID
          courseID
          pairing
          scores {
            items {
              id
              scorecardID
              holeNumber
              score
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          team {
            id
            yearID
            name
            players {
              items {
                id
                teamID
                yearID
                firstName
                lastName
                position
                sub
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            scorecards {
              items {
                id
                teamID
                courseID
                pairing
                scores {
                  items {
                    id
                    scorecardID
                    holeNumber
                    score
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                team {
                  id
                  yearID
                  name
                  players {
                    items {
                      id
                      teamID
                      yearID
                      firstName
                      lastName
                      position
                      sub
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  scorecards {
                    items {
                      id
                      teamID
                      courseID
                      pairing
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                course {
                  id
                  name
                  holes {
                    items {
                      id
                      courseID
                      number
                      par
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          course {
            id
            name
            holes {
              items {
                id
                courseID
                number
                par
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      yearID
      name
      players {
        items {
          id
          teamID
          yearID
          firstName
          lastName
          position
          sub
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      scorecards {
        items {
          id
          teamID
          courseID
          pairing
          scores {
            items {
              id
              scorecardID
              holeNumber
              score
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          team {
            id
            yearID
            name
            players {
              items {
                id
                teamID
                yearID
                firstName
                lastName
                position
                sub
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            scorecards {
              items {
                id
                teamID
                courseID
                pairing
                scores {
                  items {
                    id
                    scorecardID
                    holeNumber
                    score
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                team {
                  id
                  yearID
                  name
                  players {
                    items {
                      id
                      teamID
                      yearID
                      firstName
                      lastName
                      position
                      sub
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  scorecards {
                    items {
                      id
                      teamID
                      courseID
                      pairing
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                course {
                  id
                  name
                  holes {
                    items {
                      id
                      courseID
                      number
                      par
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          course {
            id
            name
            holes {
              items {
                id
                courseID
                number
                par
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPlayer = /* GraphQL */ `
  mutation CreatePlayer(
    $input: CreatePlayerInput!
    $condition: ModelPlayerConditionInput
  ) {
    createPlayer(input: $input, condition: $condition) {
      id
      teamID
      yearID
      firstName
      lastName
      position
      sub
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePlayer = /* GraphQL */ `
  mutation UpdatePlayer(
    $input: UpdatePlayerInput!
    $condition: ModelPlayerConditionInput
  ) {
    updatePlayer(input: $input, condition: $condition) {
      id
      teamID
      yearID
      firstName
      lastName
      position
      sub
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePlayer = /* GraphQL */ `
  mutation DeletePlayer(
    $input: DeletePlayerInput!
    $condition: ModelPlayerConditionInput
  ) {
    deletePlayer(input: $input, condition: $condition) {
      id
      teamID
      yearID
      firstName
      lastName
      position
      sub
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createScorecard = /* GraphQL */ `
  mutation CreateScorecard(
    $input: CreateScorecardInput!
    $condition: ModelScorecardConditionInput
  ) {
    createScorecard(input: $input, condition: $condition) {
      id
      teamID
      courseID
      pairing
      scores {
        items {
          id
          scorecardID
          holeNumber
          score
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      team {
        id
        yearID
        name
        players {
          items {
            id
            teamID
            yearID
            firstName
            lastName
            position
            sub
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        scorecards {
          items {
            id
            teamID
            courseID
            pairing
            scores {
              items {
                id
                scorecardID
                holeNumber
                score
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            team {
              id
              yearID
              name
              players {
                items {
                  id
                  teamID
                  yearID
                  firstName
                  lastName
                  position
                  sub
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              scorecards {
                items {
                  id
                  teamID
                  courseID
                  pairing
                  scores {
                    items {
                      id
                      scorecardID
                      holeNumber
                      score
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  team {
                    id
                    yearID
                    name
                    players {
                      nextToken
                      __typename
                    }
                    scorecards {
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  course {
                    id
                    name
                    holes {
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            course {
              id
              name
              holes {
                items {
                  id
                  courseID
                  number
                  par
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      course {
        id
        name
        holes {
          items {
            id
            courseID
            number
            par
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateScorecard = /* GraphQL */ `
  mutation UpdateScorecard(
    $input: UpdateScorecardInput!
    $condition: ModelScorecardConditionInput
  ) {
    updateScorecard(input: $input, condition: $condition) {
      id
      teamID
      courseID
      pairing
      scores {
        items {
          id
          scorecardID
          holeNumber
          score
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      team {
        id
        yearID
        name
        players {
          items {
            id
            teamID
            yearID
            firstName
            lastName
            position
            sub
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        scorecards {
          items {
            id
            teamID
            courseID
            pairing
            scores {
              items {
                id
                scorecardID
                holeNumber
                score
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            team {
              id
              yearID
              name
              players {
                items {
                  id
                  teamID
                  yearID
                  firstName
                  lastName
                  position
                  sub
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              scorecards {
                items {
                  id
                  teamID
                  courseID
                  pairing
                  scores {
                    items {
                      id
                      scorecardID
                      holeNumber
                      score
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  team {
                    id
                    yearID
                    name
                    players {
                      nextToken
                      __typename
                    }
                    scorecards {
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  course {
                    id
                    name
                    holes {
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            course {
              id
              name
              holes {
                items {
                  id
                  courseID
                  number
                  par
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      course {
        id
        name
        holes {
          items {
            id
            courseID
            number
            par
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteScorecard = /* GraphQL */ `
  mutation DeleteScorecard(
    $input: DeleteScorecardInput!
    $condition: ModelScorecardConditionInput
  ) {
    deleteScorecard(input: $input, condition: $condition) {
      id
      teamID
      courseID
      pairing
      scores {
        items {
          id
          scorecardID
          holeNumber
          score
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      team {
        id
        yearID
        name
        players {
          items {
            id
            teamID
            yearID
            firstName
            lastName
            position
            sub
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        scorecards {
          items {
            id
            teamID
            courseID
            pairing
            scores {
              items {
                id
                scorecardID
                holeNumber
                score
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            team {
              id
              yearID
              name
              players {
                items {
                  id
                  teamID
                  yearID
                  firstName
                  lastName
                  position
                  sub
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              scorecards {
                items {
                  id
                  teamID
                  courseID
                  pairing
                  scores {
                    items {
                      id
                      scorecardID
                      holeNumber
                      score
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                  team {
                    id
                    yearID
                    name
                    players {
                      nextToken
                      __typename
                    }
                    scorecards {
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  course {
                    id
                    name
                    holes {
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            course {
              id
              name
              holes {
                items {
                  id
                  courseID
                  number
                  par
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      course {
        id
        name
        holes {
          items {
            id
            courseID
            number
            par
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createScore = /* GraphQL */ `
  mutation CreateScore(
    $input: CreateScoreInput!
    $condition: ModelScoreConditionInput
  ) {
    createScore(input: $input, condition: $condition) {
      id
      scorecardID
      holeNumber
      score
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateScore = /* GraphQL */ `
  mutation UpdateScore(
    $input: UpdateScoreInput!
    $condition: ModelScoreConditionInput
  ) {
    updateScore(input: $input, condition: $condition) {
      id
      scorecardID
      holeNumber
      score
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteScore = /* GraphQL */ `
  mutation DeleteScore(
    $input: DeleteScoreInput!
    $condition: ModelScoreConditionInput
  ) {
    deleteScore(input: $input, condition: $condition) {
      id
      scorecardID
      holeNumber
      score
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTrashTalk = /* GraphQL */ `
  mutation CreateTrashTalk(
    $input: CreateTrashTalkInput!
    $condition: ModelTrashTalkConditionInput
  ) {
    createTrashTalk(input: $input, condition: $condition) {
      id
      yearID
      trashTalk
      player {
        id
        teamID
        yearID
        firstName
        lastName
        position
        sub
        createdAt
        updatedAt
        __typename
      }
      owner
      createdAt
      updatedAt
      trashTalkPlayerId
      __typename
    }
  }
`;
export const updateTrashTalk = /* GraphQL */ `
  mutation UpdateTrashTalk(
    $input: UpdateTrashTalkInput!
    $condition: ModelTrashTalkConditionInput
  ) {
    updateTrashTalk(input: $input, condition: $condition) {
      id
      yearID
      trashTalk
      player {
        id
        teamID
        yearID
        firstName
        lastName
        position
        sub
        createdAt
        updatedAt
        __typename
      }
      owner
      createdAt
      updatedAt
      trashTalkPlayerId
      __typename
    }
  }
`;
export const deleteTrashTalk = /* GraphQL */ `
  mutation DeleteTrashTalk(
    $input: DeleteTrashTalkInput!
    $condition: ModelTrashTalkConditionInput
  ) {
    deleteTrashTalk(input: $input, condition: $condition) {
      id
      yearID
      trashTalk
      player {
        id
        teamID
        yearID
        firstName
        lastName
        position
        sub
        createdAt
        updatedAt
        __typename
      }
      owner
      createdAt
      updatedAt
      trashTalkPlayerId
      __typename
    }
  }
`;
