import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { fetchAuthSession } from 'aws-amplify/auth'
import { generateClient, get } from 'aws-amplify/api'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as mutations from '../graphql/mutations'
import * as queries from '../graphql/queries'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'

const positionMap = ['A', 'B', 'C', 'D']
const client = generateClient()

const Player = (props) => {
  const initialState = { firstName: '', lastName: '', sub: '' }

  const [formState, setFormState] = useState(initialState)

  useEffect(() => {
    setFormState({
      firstName: props.firstName,
      lastName: props.lastName,
      sub: props.sub ? props.sub : '',
    })
  }, [props.firstName, props.lastName, props.sub])

  const setInput = (key, value) => {
    setFormState({ ...formState, [key]: value })
  }
  return (
    <Grid container item spacing={2} alignItems="flex-end">
      <Grid id="title-container" item>
        <Typography variant="h6">{props.position}</Typography>
      </Grid>
      <Grid item>
        <TextField
          value={formState.firstName}
          onChange={(event) => setInput('firstName', event.target.value)}
          label="First Name"
        />
      </Grid>
      <Grid item>
        <TextField
          value={formState.lastName}
          onChange={(event) => setInput('lastName', event.target.value)}
          label="Last Name"
        />
      </Grid>
      <Grid item container xs={4}>
        <FormControl fullWidth>
          <InputLabel id={`player-input-label-${props.position}`}>
            User
          </InputLabel>
          <Select
            labelId={`player-input-label-${props.position}`}
            id={`player-input-select'-${props.position}`}
            value={formState.sub}
            onChange={(event) => setInput('sub', event.target.value)}
            label="User"
          >
            {props.users.map((user) => (
              <MenuItem key={user.sub} value={user.sub}>
                {user.email}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <Button
          type="button"
          color="primary"
          onClick={() =>
            props.upsertPlayer(
              props.position,
              formState.firstName,
              formState.lastName,
              props.id,
              formState.sub !== '' ? formState.sub : undefined,
            )
          }
        >
          Save Player
        </Button>
      </Grid>
    </Grid>
  )
}

Player.propTypes = {
  id: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  upsertPlayer: PropTypes.func.isRequired,
  position: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
  sub: PropTypes.string,
}

const EditTeam = () => {
  const initialState = { name: '' }

  // State
  const [formState, setFormState] = useState(initialState)
  const [team, setTeam] = useState({})
  const [players, setPlayers] = useState([])
  const [users, setUsers] = useState([])

  // Router
  const { teamID } = useParams()
  const { yearID } = useParams()

  useEffect(() => {
    const fetchTeam = async () => {
      const teamData = await client.graphql({
        query: queries.getTeam,
        variables: { id: teamID },
      })
      const team = teamData.data.getTeam
      setTeam({ id: team.id, name: team.name })
      setPlayers([...team.players.items])
      setFormState({ name: team.name })
    }
    fetchTeam()
  }, [teamID])

  useEffect(() => {
    const fetchUsers = async () => {
      let apiName = 'AdminQueries'
      let path = '/listUsers'
      let options = {
        queryStringParameters: {},
        headers: {
          Accept: 'application/json',
          Authorization: `${(await fetchAuthSession()).tokens.accessToken}`,
        },
      }
      const { body } = await get({ apiName, path, options }).response

      const { Users } = await body.json()

      const users = Users.map((user) => {
        const sub = user.Attributes.find(
          (element) => element.Name === 'sub',
        ).Value
        const email = user.Attributes.find(
          (element) => element.Name === 'email',
        ).Value

        return { sub: sub, email: email }
      })

      setUsers(users)
    }

    fetchUsers()
  }, [])

  const setInput = (key, value) => {
    setFormState({ ...formState, [key]: value })
  }

  const updateTeam = async (event) => {
    event.preventDefault()
    try {
      if (!formState.name) return
      const teamToEdit = {
        id: team.id,
        name: formState.name,
      }

      const updatedTeamData = await client.graphql({
        query: mutations.updateTeam,
        variables: { input: teamToEdit },
      })

      setTeam(updatedTeamData.data.updateTeam)
    } catch (err) {
      console.log(err)
    }
  }

  const upsertPlayer = async (position, firstName, lastName, id, sub) => {
    if (!id) {
      const player = {
        teamID: team.id,
        yearID: yearID,
        firstName: firstName,
        lastName: lastName,
        position: position,
        sub: sub,
      }
      try {
        const createdPlayerData = await client.graphql({
          query: mutations.createPlayer,
          variables: { input: player },
        })

        const createdPlayer = createdPlayerData.data.createPlayer
        setPlayers([...players, createdPlayer])
      } catch (err) {
        console.log(err)
      }

      return
    }

    const player = {
      id: id,
      teamID: team.id,
      yearID: yearID,
      firstName: firstName,
      lastName: lastName,
      position: position,
      sub: sub,
    }

    try {
      const updatedPlayerData = await client.graphql({
        query: mutations.updatePlayer,
        variables: { input: player },
      })

      const updatedPlayer = updatedPlayerData.data.updatePlayer

      setPlayers(
        players.map((current) =>
          current.id === updatedPlayer.id ? updatedPlayer : current,
        ),
      )
    } catch (err) {
      console.log(err)
    }
  }

  const createPlayerComponents = () => {
    const playerComponents = []

    for (let i = 0; i < 4; i++) {
      let player = players.find(
        (element) => element.position === positionMap[i],
      )

      if (!player) {
        player = {
          position: positionMap[i],
          firstName: '',
          lastName: '',
          sub: '',
        }
      }

      playerComponents.push(
        <Grid key={i} item>
          <Player
            {...player}
            users={users}
            upsertPlayer={upsertPlayer}
          ></Player>
        </Grid>,
      )
    }

    return playerComponents
  }

  return (
    <Grid container direction="column" spacing={4}>
      <Grid id="title-container" item>
        <Typography variant="h1">{team.name}</Typography>
      </Grid>
      <Grid
        id="update-team-container"
        container
        item
        direction="column"
        spacing={2}
      >
        <Grid item>
          <Typography variant="h3">Update Team</Typography>
        </Grid>
        <Grid item>
          <form onSubmit={(event) => updateTeam(event)}>
            <Grid container item spacing={2} alignItems="flex-end">
              <Grid item xs={10}>
                <TextField
                  value={formState.name}
                  onChange={(event) => setInput('name', event.target.value)}
                  label="Team Name"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained" color="primary">
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Grid
        id="players-container"
        container
        item
        direction="column"
        spacing={2}
      >
        <Grid item>
          <Typography variant="h3">Players</Typography>
        </Grid>
        <Grid container item direction="column" spacing={2}>
          {createPlayerComponents()}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EditTeam
