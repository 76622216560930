import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import * as queries from '../graphql/queries'
import { generateClient } from 'aws-amplify/api'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material/'

const client = generateClient()

const Leaderboard = (props) => {
  const [year, setYear] = useState()
  const [mappedTeams, setMappedTeams] = useState([])

  useEffect(() => {
    const fetchYear = async () => {
      const calculateScoresData = await client.graphql({
        query: queries.calculateScores,
        variables: { year: props.year },
      })
      const yearWithScoresMappedByTeam =
        calculateScoresData.data.calculateScores
      const year = yearWithScoresMappedByTeam.year
      setYear({ id: year.id, name: year.name, year: year.year })
      setMappedTeams(yearWithScoresMappedByTeam.scoresMappedByTeam)
    }
    fetchYear()
  }, [props.year])

  const calculatePairingScore = (scorecard) => {
    const scores = scorecard.scores.items
    const holes = scorecard.course.holes.items
    const score = scores.reduce((total, current) => {
      const hole = holes.find(
        (element) => element.number === current.holeNumber,
      )
      if (
        current.score === undefined ||
        current.score === null ||
        current.score < 1
      ) {
        return total
      }
      return total + (current.score - hole.par)
    }, 0)

    return golfScore(score)
  }

  const golfScore = (score) => {
    if (score === 0) {
      return 'E'
    } else if (score > 0) {
      return `+${score}`
    } else {
      return score
    }
  }

  if (year && mappedTeams) {
    return (
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h5">{props.year}</Typography>
        </Grid>
        <Grid item>
          <Link component={RouterLink} to={`/years/${year.id}/trashtalk`}>
            Talk Trash!
          </Link>
        </Grid>
        <Grid container item direction="column">
          {mappedTeams.map((mappedTeam) => (
            <Accordion key={mappedTeam.team.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Grid container item spacing={2} alignItems="flex-end">
                  <Grid item>
                    <Typography>{mappedTeam.team.name}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>{golfScore(mappedTeam.score)}</Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container item direction="column" rowSpacing={2}>
                  {mappedTeam.team.scorecards.items.map((scorecard) => (
                    <Grid
                      key={scorecard.pairing}
                      item
                      container
                      columnSpacing={2}
                    >
                      <Grid item>
                        <Link
                          component={RouterLink}
                          to={`/scorecard/${mappedTeam.team.id}/${scorecard.pairing}`}
                        >
                          {scorecard.pairing}
                        </Link>
                      </Grid>
                      <Grid item>{calculatePairingScore(scorecard)}</Grid>
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  )
}

Leaderboard.propTypes = {
  year: PropTypes.string.isRequired,
}

export default Leaderboard
