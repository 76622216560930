import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Button,
} from '@mui/material'
import { generateClient } from 'aws-amplify/api'
import React, { useEffect, useState } from 'react'
import * as mutations from '../../graphql/mutations'
import { listTeams, listYears } from '../../graphql/queries'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'

const client = generateClient()

const Scores = () => {
  const [years, setYears] = useState([])
  const [year, setYear] = useState('')
  const [teams, setTeams] = useState([])

  useEffect(() => {
    const fetchYears = async () => {
      const yearsData = await client.graphql({
        query: listYears,
      })
      const years = yearsData.data.listYears.items
      setYears(years)
    }

    fetchYears()
  }, [])

  const fetchTeamsByYear = async () => {
    const teamData = await client.graphql({
      query: listTeams,
      variables: {
        filter: {
          yearID: {
            eq: year,
          },
        },
      },
    })
    setTeams(teamData.data.listTeams.items)
  }

  useEffect(() => {
    if (year != '') {
      fetchTeamsByYear()
    }
  }, [year])

  const onYearChange = (event) => {
    setYear(event.target.value)
  }

  const onClickDelete = async (score) => {
    console.log(score)
    const deletedScore = await client.graphql({
      query: mutations.deleteScore,
      variables: { input: { id: score } },
    })
    console.log(deletedScore)
  }

  return (
    <>
      <Grid container spacing={2} direction="column">
        <Grid item xs>
          <FormControl fullWidth>
            <InputLabel id="year-label">Year</InputLabel>
            <Select
              labelId="year-label"
              id="year"
              value={year}
              label="Year"
              onChange={onYearChange}
            >
              {years.map((year) => (
                <MenuItem key={year.id} value={year.id}>
                  {year.year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs>
          {teams.map((team) => {
            return (
              <>
                <h2 key={team.id}>{team.name}</h2>
                {team.scorecards.items.map((scorecard) => {
                  return (
                    <>
                      <h3 key={scorecard.id}>{scorecard.pairing}</h3>
                      <CreateScore scorecardID={scorecard.id}></CreateScore>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Hole</TableCell>
                            <TableCell>Score</TableCell>
                            <TableCell>Delete</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {scorecard.scores.items.map((score) => {
                            return (
                              <TableRow key={score.id}>
                                <TableCell>{score.holeNumber}</TableCell>
                                <TableCell>{score.score}</TableCell>
                                <TableCell>
                                  <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() => onClickDelete(score.id)}
                                  >
                                    Delete
                                  </Button>
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </>
                  )
                })}
              </>
            )
          })}
        </Grid>
      </Grid>
    </>
  )
}

const CreateScore = (props) => {
  const [holeNumber, setHoleNumber] = useState('')

  const createScore = async () => {
    await client.graphql({
      query: mutations.createScore,
      variables: {
        input: {
          scorecardID: props.scorecardID,
          holeNumber: parseInt(holeNumber),
        },
      },
    })
  }

  return (
    <Grid container item spacing={2}>
      <Grid item>
        <TextField
          value={holeNumber}
          onChange={(event) => setHoleNumber(event.target.value)}
          label="Hole Number"
        />
      </Grid>
      <Grid item>
        <Button type="button" color="primary" onClick={createScore}>
          Create Score
        </Button>
      </Grid>
    </Grid>
  )
}

CreateScore.propTypes = {
  scorecardID: PropTypes.string.isRequired,
}

export default Scores
