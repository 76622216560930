import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Leaderboard from './Leaderboard'
import { useParams } from 'react-router-dom'
import * as queries from '../graphql/queries'
import { fetchAuthSession } from 'aws-amplify/auth'
import { generateClient } from 'aws-amplify/api'
import CenteredSpinner from './CenteredSpinner'
import Scorecard from './Scorecard'

const client = generateClient()
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

export default function FullWidthTabs() {
  const [value, setValue] = useState(0)
  const [player, setPlayer] = useState()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index) => {
    setValue(index)
  }

  const params = useParams()
  const yearParam = params['year'] ? params['year'] : '2024'

  useEffect(() => {
    const fetchPlayer = async () => {
      const yearByYearData = await client.graphql({
        query: queries.yearByYear,
        variables: { year: yearParam },
      })
      const yearID = yearByYearData.data.yearByYear.items[0].id

      const session = await fetchAuthSession()

      const sub = session.tokens.idToken.payload.sub

      const playerData = await client.graphql({
        query: queries.playerBySubByYearID,
        variables: {
          sub: sub,
          yearID: { eq: yearID },
        },
      })

      const players = playerData.data.playerBySubByYearID.items
      if (players.length > 0) {
        setPlayer(players[0])
      }
    }
    if (yearParam) {
      fetchPlayer()
    }
  }, [yearParam])

  return (
    <>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Leaderboard" {...a11yProps(0)} />
          <Tab label="Day 1" {...a11yProps(1)} />
          <Tab label="Day 2" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <SwipeableViews axis="x" index={value} onChangeIndex={handleChangeIndex}>
        <TabPanel value={value} index={0}>
          <Leaderboard year={yearParam}></Leaderboard>
        </TabPanel>
        <TabPanel value={value} index={1}>
          {player ? (
            <Scorecard teamID={player.teamID} pairing="ABCD"></Scorecard>
          ) : (
            <CenteredSpinner />
          )}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {player ? (
            <Scorecard
              teamID={player.teamID}
              pairing={
                player.position === 'A' || player.position === 'D' ? 'AD' : 'BC'
              }
            ></Scorecard>
          ) : (
            <CenteredSpinner />
          )}
        </TabPanel>
      </SwipeableViews>
    </>
  )
}
