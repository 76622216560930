/* src/App.js */
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { fetchAuthSession } from 'aws-amplify/auth'
import { generateClient } from 'aws-amplify/api'
import React, { useEffect, useState } from 'react'
import * as mutations from '../graphql/mutations'
import * as queries from '../graphql/queries'
import { useParams } from 'react-router-dom'

const client = generateClient()

const TrashTalk = () => {
  const initialState = { trashTalk: '' }
  const [formState, setFormState] = useState(initialState)
  const [trashTalks, setTrashTalks] = useState([])
  const [player, setPlayer] = useState()

  const { yearID } = useParams()

  useEffect(() => {
    const fetchTrashTalk = async () => {
      const trashTalkData = await client.graphql({
        query: queries.listTrashTalks,
        variables: {
          filter: { yearID: { eq: yearID } },
        },
      })

      const trashTalks = trashTalkData.data.listTrashTalks.items
      setTrashTalks(trashTalks)
    }
    fetchTrashTalk()
  }, [yearID])

  useEffect(() => {
    const fetchPlayer = async () => {
      const session = await fetchAuthSession()

      const sub = session.tokens.idToken.payload.sub

      const playerData = await client.graphql({
        query: queries.playerBySubByYearID,
        variables: {
          sub: sub,
          yearID: { eq: yearID },
        },
      })

      const players = playerData.data.playerBySubByYearID.items
      if (players.length > 0) {
        setPlayer(players[0])
      }
    }
    if (yearID) {
      fetchPlayer()
    }
  }, [yearID])

  const setInput = (key, value) => {
    setFormState({ ...formState, [key]: value })
  }

  const addTrashTalk = async (event) => {
    event.preventDefault()
    try {
      if (!formState.trashTalk || !player) return
      const trashTalkToCreate = {
        yearID: yearID,
        trashTalk: formState.trashTalk,
        trashTalkPlayerId: player.id,
      }

      const createdTrashTalkData = await client.graphql({
        query: mutations.createTrashTalk,
        variables: {
          input: trashTalkToCreate,
        },
      })
      setFormState(initialState)
      setTrashTalks([...trashTalks, createdTrashTalkData.data.createTrashTalk])
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="h4">Trash Talk</Typography>
      </Grid>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <TextField
            multiline
            rows={4}
            value={formState.trashTalk}
            onChange={(event) => setInput('trashTalk', event.target.value)}
            label="Talk Some Trash!"
          ></TextField>
        </Grid>
        <Grid item>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={addTrashTalk}
          >
            Save
          </Button>
        </Grid>
        <Grid container item direction="column" spacing={2}>
          {trashTalks.map((trashTalk) => (
            <Grid
              key={trashTalk.id}
              container
              item
              direction="column"
              spacing={1}
            >
              <Grid item>
                <Typography variant="h6">
                  {trashTalk.player.lastName}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">{trashTalk.trashTalk}</Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TrashTalk
