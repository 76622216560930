/* src/App.js */
import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { createCourse } from '../graphql/mutations'
import { listCourses } from '../graphql/queries'
import { generateClient } from 'aws-amplify/api'
import Button from '@mui/material/Button'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'

const initialState = { name: '' }

const client = generateClient()

const Courses = () => {
  const [formState, setFormState] = useState(initialState)
  const [courses, setCourses] = useState([])

  useEffect(() => {
    fetchCourses()
  }, [])

  const setInput = (key, value) => {
    setFormState({ ...formState, [key]: value })
  }

  const fetchCourses = async () => {
    const coursesData = await client.graphql({ query: listCourses })
    const courses = coursesData.data.listCourses.items
    setCourses(courses)
  }

  const addCourse = async (event) => {
    event.preventDefault()
    try {
      if (!formState.name) return
      const course = { ...formState }

      setCourses([...courses, course])
      setFormState(initialState)
      await client.graphql({
        query: createCourse,
        variables: { input: course },
      })
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="h1">Courses</Typography>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <form onSubmit={(event) => addCourse(event)}>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Typography variant="h3">Create</Typography>
              </Grid>
              <Grid item>
                <TextField
                  onChange={(event) => setInput('name', event.target.value)}
                  label="Name"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained" color="primary">
                  Add
                </Button>
              </Grid>
              <Grid item>
                <Typography variant="h3">List</Typography>
              </Grid>
              <Grid container item direction="column" rowSpacing={2}>
                {courses.map((course) => (
                  <Grid item key={course.name}>
                    <Link component={RouterLink} to={`/courses/${course.id}`}>
                      {course.name}
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Courses
