import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import * as mutations from '../graphql/mutations'
import * as queries from '../graphql/queries'
import { generateClient } from 'aws-amplify/api'
import { useParams } from 'react-router-dom'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'

import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

const client = generateClient()

const EditYear = () => {
  const initialState = { name: '', year: 2020 }

  // State
  const [formState, setFormState] = useState(initialState)
  const [year, setYear] = useState()
  const [courses, setCourses] = useState([])
  const [day1Course, setDay1Course] = useState('')
  const [day2Course, setDay2Course] = useState('')
  const [teamName, setTeamName] = useState()
  const [teams, setTeams] = useState([])

  // Router
  const { yearID } = useParams()

  const setInput = (key, value) => {
    setFormState({ ...formState, [key]: value })
  }

  const addTeam = async (event) => {
    event.preventDefault()
    try {
      if (!teamName) return
      const team = {
        name: teamName,
        yearID: year.id,
      }
      setTeamName()
      await client.graphql({
        query: mutations.createTeam,
        variables: { input: team },
      })
    } catch (err) {
      console.log(err)
    }
  }

  const updateYear = async (event) => {
    event.preventDefault()
    try {
      if (!formState.name || !formState.year) return
      const yearToEdit = {
        id: year.id,
        name: formState.name,
        year: formState.year,
        yearDay1CourseId: day1Course,
        yearDay2CourseId: day2Course,
      }

      const updatedYearData = await client.graphql({
        query: mutations.updateYear,
        variables: { input: yearToEdit },
      })

      setYear(updatedYearData.data.updateYear)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    const fetchYear = async () => {
      const yearData = await client.graphql({
        query: queries.getYear,
        variables: { id: yearID },
      })
      const year = yearData.data.getYear
      setYear({ id: year.id, name: year.name, year: year.year })
      if (year.day1Course) {
        setDay1Course(year.day1Course.id)
      }
      if (year.day2Course) {
        setDay2Course(year.day2Course.id)
      }
      setFormState({ name: year.name, year: year.year })
    }
    fetchYear()
  }, [yearID])

  useEffect(() => {
    const fetchCourses = async () => {
      const coursesData = await client.graphql({ query: queries.listCourses })
      const courses = coursesData.data.listCourses.items
      setCourses(courses)
    }
    fetchCourses()
  }, [])

  useEffect(() => {
    const fetchTeams = async () => {
      const teamsData = await client.graphql({
        query: queries.listTeams,
        variables: {
          filter: { yearID: { eq: year.id } },
        },
      })
      const teams = teamsData.data.listTeams.items
      setTeams(teams)
    }
    if (year) {
      fetchTeams()
    }
  }, [year])

  const createCourseMenuItems = () => {
    const courseMenuItems = []
    for (const course of courses) {
      courseMenuItems.push(
        <MenuItem key={course.id} value={course.id}>
          {course.name}
        </MenuItem>,
      )
    }

    return courseMenuItems
  }

  const addScorecard = async (teamID, courseID, pairing) => {
    try {
      const scorecardResponse = await client.graphql({
        query: mutations.createScorecard,
        variables: {
          input: { teamID: teamID, courseID: courseID, pairing: pairing },
        },
      })
      const scorecardID = scorecardResponse.data.createScorecard.id

      for (let holeNumber = 1; holeNumber < 19; holeNumber++) {
        await client.graphql({
          query: mutations.createScore,
          variables: {
            input: { scorecardID: scorecardID, holeNumber: holeNumber },
          },
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const createScorecards = (event) => {
    event.preventDefault()
    for (const team of teams) {
      addScorecard(team.id, day1Course, 'ABCD')
      addScorecard(team.id, day2Course, 'AD')
      addScorecard(team.id, day2Course, 'BC')
    }
  }

  const deleteScorecard = async (scorecardId) => {
    try {
      await client.graphql({
        query: mutations.deleteScorecard,
        variables: {
          input: { id: scorecardId },
        },
      })
    } catch (err) {
      console.log(err)
    }
  }

  const deleteScore = async (scoreId) => {
    try {
      await client.graphql({
        query: mutations.deleteScore,
        variables: {
          input: { id: scoreId },
        },
      })
    } catch (err) {
      console.log(err)
    }
  }

  const clearScorecards = (event) => {
    event.preventDefault()
    for (const team of teams) {
      for (const scorecard of team.scorecards.items) {
        for (const score of scorecard.scores.items) {
          deleteScore(score.id)
        }
        deleteScorecard(scorecard.id)
      }
    }
  }

  if (year) {
    return (
      <Grid container direction="column" spacing={4}>
        <Grid id="title-container" item>
          <Typography variant="h1">{year.name}</Typography>
          <Typography variant="h4">{year.year}</Typography>
        </Grid>
        <Grid
          id="update-year-container"
          container
          item
          direction="column"
          spacing={2}
        >
          <Grid item>
            <Typography variant="h3">Update Year</Typography>
          </Grid>
          <Grid item id="form-container">
            <form onSubmit={(event) => updateYear(event)}>
              <Grid
                container
                item
                id="form-elements-container"
                spacing={2}
                direction="column"
              >
                <Grid
                  id="meta-container"
                  container
                  item
                  spacing={2}
                  direction="column"
                >
                  <Grid item>
                    <TextField
                      value={formState.name}
                      onChange={(event) => setInput('name', event.target.value)}
                      label="Name"
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      value={formState.year}
                      onChange={(event) => setInput('year', event.target.value)}
                      label="Year"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid
                  id="teams-container"
                  container
                  item
                  spacing={2}
                  direction="column"
                >
                  <Grid item>
                    <Typography variant="h3">Teams</Typography>
                  </Grid>
                  <Grid container item spacing={2} alignItems="flex-end">
                    <Grid item xs={10}>
                      <TextField
                        value={teamName}
                        onChange={(event) => setTeamName(event.target.value)}
                        label="New Team Name"
                        fullWidth
                      />
                    </Grid>
                    <Grid item>
                      <Button type="button" color="primary" onClick={addTeam}>
                        Add Team
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container item direction="column" spacing={1}>
                    {teams.map((team) => (
                      <Grid key={team.id} item>
                        <Link
                          component={RouterLink}
                          to={`/years/${year.id}/teams/${team.id}`}
                        >
                          {team.name}
                        </Link>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid item>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={createScorecards}
                    >
                      Create Scorecards
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={clearScorecards}
                    >
                      Clear Scorecards
                    </Button>
                  </Grid>
                </Grid>
                <Grid id="courses-container" container item>
                  <Grid container item spacing={2} direction="column">
                    <Grid item>
                      <Typography variant="h3">Courses</Typography>
                    </Grid>
                    <Grid container item direction="column" spacing={2}>
                      <Grid item>
                        <FormControl fullWidth>
                          <InputLabel id="day-1-course-label">
                            Day 1 Course
                          </InputLabel>
                          <Select
                            labelId="day-1-course-label"
                            id="day-1-course-select"
                            value={day1Course}
                            onChange={(event) =>
                              setDay1Course(event.target.value)
                            }
                            label="Day 1 Course"
                          >
                            {createCourseMenuItems()}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <FormControl fullWidth>
                          <InputLabel id="day-2-course-label">
                            Day 2 Course
                          </InputLabel>
                          <Select
                            labelId="day-2-course-label"
                            id="day-2-course-select"
                            value={day2Course}
                            onChange={(event) =>
                              setDay2Course(event.target.value)
                            }
                            label="Day 2 Course"
                          >
                            {createCourseMenuItems()}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Button type="submit" variant="contained" color="primary">
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  return <div></div>
}

export default EditYear
