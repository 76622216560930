/* src/App.js */
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { generateClient } from 'aws-amplify/api'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as mutations from '../graphql/mutations'
import * as queries from '../graphql/queries'

const client = generateClient()

const Hole = (props) => {
  const [par, setPar] = useState()

  useEffect(() => {
    setPar(props.par)
  }, [props.par])

  const onParClicked = (par) => {
    setPar(par)
    props.upsertHole(props.number, par, props.id)
  }

  return (
    <Grid container item direction="row" spacing={2} alignItems="center">
      <Grid item>
        <Typography variant="body1">{props.number}</Typography>
      </Grid>
      <Grid item>
        <ButtonGroup variant="outlined" color="primary">
          <Button
            onClick={() => onParClicked(3)}
            variant={par === 3 ? 'contained' : 'outlined'}
          >
            3
          </Button>
          <Button
            onClick={() => onParClicked(4)}
            variant={par === 4 ? 'contained' : 'outlined'}
          >
            4
          </Button>
          <Button
            onClick={() => onParClicked(5)}
            variant={par === 5 ? 'contained' : 'outlined'}
          >
            5
          </Button>
        </ButtonGroup>
      </Grid>
    </Grid>
  )
}

Hole.propTypes = {
  number: PropTypes.number.isRequired,
  upsertHole: PropTypes.func.isRequired,
  par: PropTypes.number,
  id: PropTypes.string,
}

const EditCourse = () => {
  const initialState = { name: '' }

  // State
  const [formState, setFormState] = useState(initialState)
  const [course, setCourse] = useState({})
  const [holes, setHoles] = useState([])

  // Router
  const { courseID } = useParams()

  useEffect(() => {
    const fetchCourse = async () => {
      const courseData = await client.graphql({
        query: queries.getCourse,
        variables: { id: courseID },
      })
      const course = courseData.data.getCourse
      setCourse({ id: course.id, name: course.name })
      setHoles([...course.holes.items])
      setFormState({ name: course.name })
    }
    fetchCourse()
  }, [courseID])

  const setInput = (key, value) => {
    setFormState({ ...formState, [key]: value })
  }

  const updateCourse = async (event) => {
    event.preventDefault()
    try {
      if (!formState.name) return
      const courseToEdit = {
        id: course.id,
        name: formState.name,
      }

      const updatedCourseData = await client.graphql({
        query: mutations.updateCourse,
        variables: { input: courseToEdit },
      })

      setCourse(updatedCourseData.data.updateCourse)
    } catch (err) {
      console.log(err)
    }
  }

  const upsertHole = async (number, par, id) => {
    if (!id) {
      const hole = {
        courseID: course.id,
        par: par,
        number: number,
      }

      try {
        const createdHoleData = await client.graphql({
          query: mutations.createHole,
          variables: { input: hole },
        })

        const createdHole = createdHoleData.data.createHole

        setHoles([...holes, createdHole])
      } catch (err) {
        console.log(err)
      }

      return
    }

    const hole = {
      id: id,
      courseID: course.id,
      par: par,
      number: number,
    }

    try {
      const updatedHoleData = await client.graphql({
        query: mutations.updateHole,
        variables: { input: hole },
      })

      const updatedHole = updatedHoleData.data.updateHole

      setHoles(
        holes.map((current) =>
          current.id === updatedHole.id ? updatedHole : current,
        ),
      )
    } catch (err) {
      console.log(err)
    }
  }

  const createHoleComponents = () => {
    const holeComponents = []
    for (let i = 1; i < 19; i++) {
      let hole = holes.find((element) => element.number === i)

      if (!hole) {
        hole = {
          number: i,
        }
      }

      holeComponents.push(
        <Grid item key={i}>
          <Hole {...hole} upsertHole={upsertHole}></Hole>
        </Grid>,
      )
    }

    return holeComponents
  }

  return (
    <Grid container direction="column" spacing={4}>
      <Grid id="title-container" item>
        <Typography variant="h1">{course.name}</Typography>
      </Grid>
      <Grid
        id="update-course-container"
        container
        item
        direction="column"
        spacing={2}
      >
        <Grid item>
          <Typography variant="h3">Update Course</Typography>
        </Grid>
        <Grid item>
          <form onSubmit={(event) => updateCourse(event)}>
            <Grid container item spacing={2} alignItems="flex-end">
              <Grid item xs={10}>
                <TextField
                  value={formState.name}
                  onChange={(event) => setInput('name', event.target.value)}
                  label="Course Name"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained" color="primary">
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Grid id="holes-container" container item>
        <Grid container item spacing={2} direction="column">
          <Grid item>
            <Typography variant="h3">Holes</Typography>
          </Grid>
          <Grid container item direction="column" spacing={2}>
            {createHoleComponents()}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EditCourse
